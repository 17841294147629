import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {EntitiesService} from '../../services/entities/entities.service';
import {EntityTypeCode} from '../../services/entities/entity-type.class';
import {Entity} from '../../classes/entity.class';
import {PlanningHasService} from '../../services/planning/planning-has.service';
import {Utils} from '../../utils.class';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {Subscription} from 'rxjs';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {VkmService} from '../../services/vkm.service';
import {ActivatedRoute, Router} from '@angular/router';
import {formatDate} from '@angular/common';

@Component({
    selector: 'app-vkm-day-planning',
    templateUrl: './vkm-day-planning.component.html',
    styleUrls: ['./vkm-day-planning.component.scss']
})
export class VkmDayPlanningComponent extends CodaltComponent implements OnInit {

    currentDate = new Date();

    teams: Entity[];
    teamLists = [];
    planningHass: PlanningHasEntity[];

    trigger = 0;

    planningSubscription = new Subscription();

    constructor(private entitiesService: EntitiesService,
                private vkmService: VkmService,
                private planningHasService: PlanningHasService,
                private activatedRoute: ActivatedRoute,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { date }) => {
            let date = params.date;
            if (!date || date.length === 0) {
                date = new Date();
            }
            this.currentDate = new Date(date);
            Utils.setTime(this.currentDate, 0, 0);
            this.getData();
        }));
    }

    private getData() {
        this.planningSubscription.unsubscribe();
        this.planningSubscription = new Subscription();
        this.planningSubscription.add(this.entitiesService.getByType([EntityTypeCode.VKMPloeg, EntityTypeCode.VKMVakman]).subscribe(entities => {
            this.teams = [{id: 900, name: 'Geen ploeg'} as any, ...entities];
            this.teamLists = this.teams.map(t => `team-${t.id}`);
            const beginDate = new Date(this.currentDate);
            Utils.setTime(beginDate, 0, 0);
            const endDate = new Date(this.currentDate);
            Utils.setTime(endDate, 23, 59);
            const planningHass$ = this.planningHasService.getFilteredList(beginDate, endDate, EntityTypeCode.VKMWerkopdracht);
            this.planningSubscription.add(planningHass$.subscribe(planningHas => {
                this.planningHass = planningHas;
            }));
        }));
    }

    today() {
        this.currentDate = new Date();
        this.router.navigateByUrl(`vkm-planning/${formatDate(this.currentDate, 'yyyy-MM-dd', 'nl')}`);
    }

    next() {
        this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() + 1));
        this.router.navigateByUrl(`vkm-planning/${formatDate(this.currentDate, 'yyyy-MM-dd', 'nl')}`);
    }

    prev() {
        this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() - 1));
        this.router.navigateByUrl(`vkm-planning/${formatDate(this.currentDate, 'yyyy-MM-dd', 'nl')}`);
    }

    drop(event: CdkDragDrop<string[]>) {
        const team = event.container.data as any as Entity;
        event.item.data.entity_id = team.id;
        this.trigger++;
        this.planningSubscription.add(this.vkmService.entityUpdate(event.item.data).subscribe());
    }

    add(entity: Entity) {
        const duration = 4;
        const hour = 7;
        const fromDate = new Date(this.currentDate);
        const toDate = new Date(this.currentDate);
        fromDate.setHours(hour);
        toDate.setHours(hour);
        toDate.setMinutes(toDate.getMinutes() + (60 * duration));
        this.router.navigate([`vkm/new`, {fromDate: fromDate.toISOString(), toDate: toDate.toISOString(), entityId: entity.id, backTo: 'vkm-planning'}]);
    }

    open(planningHas: PlanningHasEntity) {
        this.router.navigate([
            `vkm/${planningHas.planning_id}`,
            {
                backTo: `vkm-planning/${formatDate(this.currentDate, 'yyyy-MM-dd', 'nl')}`,
                planningHas: planningHas.id
            }
        ]);
    }
}
