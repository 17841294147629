import {Pipe, PipeTransform} from '@angular/core';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {Utils} from '../../utils.class';

@Pipe({
    name: 'teamHasPlanning'
})
export class TeamHasPlanningPipe implements PipeTransform {

    transform(planningHass: PlanningHasEntity[], entityId: number, trigger?: any): PlanningHasEntity[] {
        return planningHass?.filter(ph => ph.entity_id === entityId)
            .sort((a, b) => Utils.getTimeOrNull(a.begindate) - Utils.getTimeOrNull(b.begindate));
    }

}
