<div class="controls">
    <div class="header">
        <app-header [mainTitle]="'Planning DvdS'"
                    subTitle="Verkeersmaatregelen">
        </app-header>
    </div>
    <div class="indicator">
        <app-date-indicator (next)="next()"
                            (prev)="prev()"
                            [singleDate]="currentDate">
        </app-date-indicator>
    </div>
    <div class="print-pdf">

    </div>
    <div class="actions">
        <app-settings-menu></app-settings-menu>
    </div>
</div>
<div class="teams">
    <div *ngFor="let team of teams" class="team" >
        <div class="team-name">
            {{team.name}}
        </div>
        <div cdkDropList
             cdkDropListSortingDisabled
             (cdkDropListDropped)="drop($event)"
             class="team-list"
             [cdkDropListConnectedTo]="teamLists"
             id="team-{{team.id}}"
             [cdkDropListData]="team">
            <div class="planning curpoint"
                 matRipple
                 (click)="open(planningHas)"
                 [cdkDragData]="planningHas"
                 *ngFor="let planningHas of (planningHass | teamHasPlanning : team.id : trigger)" cdkDrag>
                <b>{{planningHas.afas_project_id}}</b> - {{planningHas.type}}<br>
                <u>{{planningHas.begindate | date : 'HH:mm'}} - {{planningHas.enddate | date : 'HH:mm'}}</u><br>
                <div class="location" *ngIf="planningHas.location">
                    <i class="fa-sharp-duotone fa-solid fa-location-dot"></i> {{planningHas.location}}
                </div>
                <div class="text">
                    {{planningHas.comment}}
                </div>
            </div>
            <div class="planning-add">
                <button class="w-100" mat-button color="primary" (click)="add(team)">Toevoegen</button>
            </div>
        </div>
    </div>
</div>
