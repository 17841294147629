<div class="controls" *ngIf="!print">
    <div class="header">
        <app-header [mainTitle]="'Periode'"
                    subTitle="verrekening">
        </app-header>
    </div>
    <div class="indicator">
        <app-date-indicator (next)="next()"
                            (prev)="prev()"
                            [fromDate]="beginDate"
                            [toDate]="endDate">
        </app-date-indicator>
    </div>
    <div class="print-pdf mr-3" *ngIf="!print">
        <button (click)="pdf()" color="primary" mat-icon-button>
            <i class="fa-light fa-file-pdf" *ngIf="!downloadingPdf"></i>
            <i class="fas fa-spinner fa-spin" *ngIf="downloadingPdf"></i>
        </button>
    </div>
    <div class="actions">
        <app-settings-menu></app-settings-menu>
    </div>
</div>
<div class="wrapper" *ngIf="user">
    <div class="header">
        <div class="tables">
            <div class="employee-info">
                <div class="employee" *ngIf="!readonly">
                    <button (click)="prevEmployee()" color="primary" mat-icon-button>
                        <i class="fas fa-chevron-left"></i>
                    </button>
                    <ng-select [formControl]="fcUserId"
                               [items]="employees"
                               [searchFn]="customSearchFn"
                               [clearable]="false"
                               bindValue="id">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item">
                            <ng-container *ngIf="!readonly">
                                <small>{{item.group}} {{item.afas_employee_id.padStart(4, '0')}}</small>
                                {{item.lastname}}, {{item.firstname}}
                            </ng-container>
                            <ng-container *ngIf="readonly">
                                {{item.name}}
                            </ng-container>
                        </ng-template>
                    </ng-select>
                    <button (click)="nextEmployee()" color="primary" mat-icon-button>
                        <i class="fas fa-chevron-right"></i>
                    </button>
                </div>
                <div class="employee flex-column align-items-start justify-content-center" *ngIf="readonly">
                    <h1 class="mb-1">{{user.name}}</h1>
                    <span>{{user.email}}</span>
                    <span>Personeelsnummer <strong>{{user.afas_employee_id ?? '-'}}</strong></span>
                </div>
                <div class="basictable">
                    <table>
                        <tr>
                            <td>
                                Uren periode {{period  | number:'1.0-0'}} - {{year}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Uitbetalen in periode {{((period + 1) > 13 ? 1 : (period + 1)) | number:'1.0-0'}} - {{period == 13 ? (year + 1) : year}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{user.function}}
                                <ng-container *ngIf="!readonly">
                                     ({{user.function?.toLowerCase()?.indexOf('asfalt') !== -1 ? 'asfalt ' : 'non-asfalt'}})
                                </ng-container>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="d-flex align-items-center text-center pl-5" *ngIf="readonly">
                    <i>Versie: {{today | date : 'EEEE d MMMM y HH:mm'}}</i>
                </div>
            </div>
            <div class="pb-4" *ngIf="!readonly">
                <button mat-raised-button *ngIf="screenContent === 'verrekening'" class="mr-3" color="primary"
                        routerLink="/periode/boekingsregels/{{this.user.id}}/{{period}}/{{year}}">
                    Boekingsregels
                </button>
                <button mat-raised-button *ngIf="screenContent === 'boekingsregels'" color="primary"
                        routerLink="/periode/verrekening/{{this.user.id}}/{{period}}/{{year}}">
                    Periodeverrekening
                </button>
            </div>

            <div>
                <div class="basictable contract-days">
                    <table>
                        <tr>
                            <th>
                                Contractdagen
                            </th>
                            <th class="text-right text-nowrap">
                                {{(user.contract_hours * 4) / 8}} dagen
                            </th>
                            <th class="text-right text-nowrap">
                                {{user.contract_hours * 4}} uur
                            </th>
                        </tr>
                        <tr *ngFor="let dtc of daytypeCounts">
                            <td>
                                {{dtc.type}}
                            </td>
                            <td class="text-right text-nowrap">
                                {{dtc.count}} dagen
                            </td>
                            <td class="text-right text-nowrap">
                                {{dtc.minutes | minutes}} uur
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <img src="/assets/clean-logo.svg" *ngIf=readonly height="85">
        </div>
    </div>
</div>
<div class="content" *ngIf="user" [class.print]="print">
    <app-period-settlement [print]="print"
                           *ngIf="screenContent === 'verrekening'"
                           [daytypeCounts]="daytypeCounts"
                           [beginDate]="beginDate"
                           [endDate]="endDate"
                           [user]="user"></app-period-settlement>
    <app-settlements-send *ngIf="screenContent === 'boekingsregels'"
                          [hideDateSelection]="true"
                          [beginDate]="beginDate"
                          [endDate]="endDate"
                          [user]="user"></app-settlements-send>
</div>

